<template>
  <div class="download" id="download">
    <a  target="_blank" rel="noopener noreferrer" v-if="page?.appStoreURL" v-bind:href="page.appStoreURL">
      <img class="mr-2" src="../assets/ios.png" />
    </a>
    <a  target="_blank" rel="noopener noreferrer" v-if="page?.playStoreURL"  v-bind:href="page.playStoreURL">
      <img src="../assets/google.png" />
    </a>
  </div>
</template>
<script>
export default {
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  height: 60px;
  width: 180px;
}
.download {
  margin: 20px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>