<template>
  <div class="reviews shadow" v-if="page.reviews.length > 0">
    <div class="items">
      <carousel
        :items-to-show="5"
        :settings="settings"
        :breakpoints="breakpoints"
      >
        <slide v-for="review in page.reviews" :key="review.id">
          <div class="profile row">
            <div class="col-xs-5 col-md-5 col-lg-4" style="margin-bottom: 10px">
              <div class="title">{{ review.title }}</div>
              <div class="description">{{ review.description }}</div>
              <div class="name">
                - {{ review?.firstName }} {{ review?.lastName }}
              </div>
            </div>
            <div class="col-xs-5 col-md-5 col-lg-4" style="margin-bottom: 10px">
              <img v-bind:src="review.photoURL" />
            </div>
          </div>
          <!-- <img :src="brand.url" /> -->
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
  updated() {
    const carouselNext = document.getElementsByClassName("carousel__next");
    const carouselPrev = document.getElementsByClassName("carousel__prev");
    const carouselButtons = document.getElementsByClassName(
      "carousel__pagination-button"
    );

    // on click prev
    Object.keys(carouselPrev).map((key) => {
      const btn = carouselPrev[key];
      btn.addEventListener("click", () => {
        if (carouselButtons) {
          Object.keys(carouselButtons).map((key) => {
            const btn = carouselButtons[key];
            btn.addEventListener("click", () => {
              btn.style["background"] = this.page.themeColor;
              btn.style["backgroundColor"] = this.page.themeColor;

              var carouselButtons = document.getElementsByClassName(
                "carousel__pagination-button"
              );
              if (carouselButtons) {
                Object.keys(carouselButtons).map((key) => {
                  const btn = carouselButtons[key];
                  const classList = Object.keys(btn.classList).map(
                    (key) => btn.classList[key]
                  );
                  if (
                    !classList.some(
                      (x) => x === "carousel__pagination-button--active"
                    )
                  ) {
                    btn.style["background"] = "#29282842";
                    btn.style["backgroundColor"] = "#29282842";
                  }
                });
              }
            });
            const classList = Object.keys(btn.classList).map(
              (key) => btn.classList[key]
            );
            if (
              !classList.some(
                (x) => x === "carousel__pagination-button--active"
              )
            ) {
              btn.style["background"] = "#29282842";
              btn.style["backgroundColor"] = "#29282842";
            } else {
              btn.style["background"] = this.page.themeColor;
              btn.style["backgroundColor"] = this.page.themeColor;
            }
          });
        }
      });
    });

    // on click next
    Object.keys(carouselNext).map((key) => {
      const btn = carouselNext[key];
      btn.addEventListener("click", () => {
        if (carouselButtons) {
          Object.keys(carouselButtons).map((key) => {
            const btn = carouselButtons[key];
            btn.addEventListener("click", () => {
              btn.style["background"] = this.page.themeColor;
              btn.style["backgroundColor"] = this.page.themeColor;

              var carouselButtons = document.getElementsByClassName(
                "carousel__pagination-button"
              );
              if (carouselButtons) {
                Object.keys(carouselButtons).map((key) => {
                  const btn = carouselButtons[key];
                  const classList = Object.keys(btn.classList).map(
                    (key) => btn.classList[key]
                  );
                  if (
                    !classList.some(
                      (x) => x === "carousel__pagination-button--active"
                    )
                  ) {
                    btn.style["background"] = "#29282842";
                    btn.style["backgroundColor"] = "#29282842";
                  }
                });
              }
            });
            const classList = Object.keys(btn.classList).map(
              (key) => btn.classList[key]
            );
            if (
              !classList.some(
                (x) => x === "carousel__pagination-button--active"
              )
            ) {
              btn.style["background"] = "#29282842";
              btn.style["backgroundColor"] = "#29282842";
            } else {
              btn.style["background"] = this.page.themeColor;
              btn.style["backgroundColor"] = this.page.themeColor;
            }
          });
        }
      });
    });

    if (carouselNext) {
      Object.keys(carouselNext).map((key) => {
        const btn = carouselNext[key];
        btn.style["background"] = this.page.themeColor;
        btn.style["backgroundColor"] = this.page.themeColor;
      });
    }

    if (carouselPrev) {
      Object.keys(carouselPrev).map((key) => {
        const btn = carouselPrev[key];
        btn.style["background"] = this.page.themeColor;
        btn.style["backgroundColor"] = this.page.themeColor;
      });
    }
  },
  data() {
    return {
      settings: {
        itemsToShow: 5,
        snapAlign: "center",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
      },
    };
  },
  setup() {},
};
</script>

<style lang="scss">
.reviews {
  .carousel__next {
    right: 10% !important;
  }
  .carousel__prev {
    left: 10% !important;
  }
  @media only screen and (max-width: 770px) {
    .carousel__next {
      right: 30px !important;
    }
    .carousel__prev {
      left: 30px !important;
    }
  }
  width: 100%;

  .items {
    width: 100%;

    .profile {
      width: 90%;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .title {
        text-align: left;
        font-size: 16pt;
        font-weight: 600;
        margin: 20px 0;
      }

      .description {
        text-align: justify;
      }

      img {
        width: 160px;
        border-radius: 180px;
      }

      .name {
        text-align: left;
        font-weight: 600;
        margin-top: 20px;
      }
    }

    @media only screen and (max-width: 770px) {
      .profile {
        width: 75%;
      }
    }
  }
}
</style>