import { createApp } from "vue";
import App from "./App.vue";
import "vue3-carousel/dist/carousel.css";
// font awesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import { pageStore } from "./vuex/page.store";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./components/Home.vue";
import NotFound from "./components/NotFound.vue";

const routes = [
    { path: "/:id", component: Home },
    { path: "/not-found", component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

createApp(App)
  .use(router)
  .use(pageStore)
  .mount("#app");
