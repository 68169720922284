import { createStore } from "vuex";
export const pageStore = createStore({
  state: {
    page: {
      id: null,
      name: null,
      shortDescription: null,
      playStoreURL: null,
      brandsSectionTitle: null,
      appStoreURL: null,
      logoURL: null,
      videoURL: null,
      themeColor: null,
      banner: null,
      brands: [],
      reviews: [],
      previews: [],
    }
  },

  getters: {
    page: (state) => {
      return state;
    },
  },

  mutations: {
    updatePage(state, payload) {
      return state.page = payload;
    },
  },

  actions: {
    // addToCart(context) {
    //     const productId = context.state.cart
    // }
  },
});
