<template>
  <div class="features" v-if="page.previews.length > 0">
    <div
      v-for="(preview, index) in page.previews"
      :key="preview.id"
      class="section-a shadow"
      v-bind:class="{ reverse: index % 2 == 0 }"
    >
      <div class="col-xs-12 col-lg-4" v-if="preview?.photoURL">
        <img v-bind:src="preview.photoURL" alt="" />
      </div>
      <div class="col-xs-12 col-lg-4 info">
        <div class="title">{{ preview.title }}</div>
        <div class="description">
          {{ preview.description }}
        </div>
        <a href='#download' v-bind:style="{ backgroundColor: page?.themeColor }" class="button">Get {{ page?.shortDescription }} App</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
};
</script>

<style lang="scss" scoped>
.features {
  img {
    width: 200px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 15px;
  }

  .title {
    font-size: 20pt;
  }

  .description {
    line-height: 1.8;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .section-a {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
    border-bottom: 1px solid #eae9e9;
    padding: 23px 0;
  }

  .button {
    width: auto;
    padding: 10px 20px;
    color: white;
    font-size: 12pt;
    text-decoration: none;
    display: inline;
    text-align: center;
    cursor: pointer;
  }
}
</style>