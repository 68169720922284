<template>
  <MainSection />
  <Brands />
  <AppVideo />
  <InfoBanner />
  <Reviews />
  <Features />
  <Download />
</template>

<script>
import MainSection from './MainSection.vue';
import Brands from './Brands.vue';
import AppVideo from './AppVideo.vue';
import InfoBanner from './InfoBanner.vue';
import Reviews from './Reviews.vue';
import Features from './Features.vue';
import Download from './Download.vue'

import { getPageData } from '../utils/page.utils';
export default {
  components: {
    MainSection,
    Brands,
    AppVideo,
    Features,
    InfoBanner,
    Reviews,
    Download
  },
  mounted(){
    const name = this.$route.params.id;

    if (!name) {
        this.$router.push('/not-found')
    }

    getPageData(name).then((res) => {
        this.$store.commit('updatePage', res);
    }).catch(() => {
        this.$router.push('/not-found')
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
