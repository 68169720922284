<template>
  <div class="main-section">
    <div class="top-menu">
      <img
        v-if="page?.logoURL"
        v-bind:src="page.logoURL"
      />
    </div>
    <div class="banner">
      <div class="main-a">
        <div>
          <h1 class="title">{{ page?.shortDescription }}</h1>
          <h2 class="sub-title">Work with Investors like a Pro</h2>
        </div>
        <div v-if="page?.banner?.description" class="description mt-2" style="display: flex; justify-content: center">
          <span style="font-size: 15pt" v-html="page.banner.description"></span>
        </div>
        <div style="margin: 20px">
          <a href='#download' v-bind:style="{ backgroundColor: page?.themeColor }" class="button">Get  {{ page?.shortDescription }} App</a>
        </div>
      </div>
      <div class="main-b">
        <img
          v-if="page?.banner?.photoURL"
          v-bind:src="page.banner.photoURL"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    page () {
      console.log(this.$store.state.page);
      return this.$store.state.page;
    }
  }
};
</script>
<style lang="scss" scoped>
.main-section {
  .top-menu {
    height: 100px;
    position: relative;

    img {
      width: 200px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 100px;
      transform: translate(0, -50%);
    }

     @media only screen and (max-width: 770px) {
          img {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
      }
  }
  .banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .main-a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 30pt;
        margin: 0;
        font-weight: 600;
      }

      .sub-title {
        font-size: 15pt;
        font-weight: 600;
        margin: 0 0 25px;
      }

      .description {
        width: 560px;
        @media only screen and (max-width: 800px) {
          width: 100% !important;
        }
        ul {
          li {
            text-align: left;
            font-size: 14pt;
            margin: 15px 0;
          }
        }
      }

      @media only screen and (max-width: 600px) {
        width: 360px;
      }
    }

    .main-b {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      img {
        width: 500px;
      }

       @media only screen and (max-width: 510px) {
         img {
           width: 300px !important;
         }
       }
    }

    .button {
      width: auto;
      padding: 10px 20px;
      color: white;
      font-size: 12pt;
      text-decoration: none;
      display: inline;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>