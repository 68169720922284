<template>
  <div class="info-banner">
    <div class="banner col-xs-12 col-sm-6 col-md-4">
      <div class="icon dollar">
        <i class="fas fa-dollar-sign"></i>
        <div class="title">Boost Your Earnings</div>
      </div>
    </div>
    <div class="banner col-xs-12 col-sm-6 col-md-4">
      <div class="icon shield">
        <i class="fas fa-shield-alt"></i>
        <div class="title">Build Client’s Trust</div>
      </div>
    </div>
    <div class="banner col-xs-12 col-sm-6 col-md-4">
      <div class="icon">
        <i class="far fa-newspaper"></i>
        <div class="title">Be More Relevant</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss">
.info-banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 20px 0;
  background-color: #eaeaea;

  .banner {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 20pt;
        margin-top: 10px;
        color: #2c3e50;
    }

    .dollar {
        color: #46B868;
    }

    .shield {
        color: #0D2F6E;
    }

    .icon {
      font-size: 50pt;
    }
  }
}
</style>