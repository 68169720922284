<template>
  <div class="brands" v-if="page.brands.length > 0">
    <h2 class="title">
      {{ page?.brandsSectionTitle }}
    </h2>
    <div class="items">
      <carousel
        :items-to-show="5"
        :settings="settings"
        :breakpoints="breakpoints"
      >
        <slide v-for="brand in page.brands" :key="brand.id">
          <img :src="brand.photoURL" />
        </slide>

        <template #addons>
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
  data() {
    return {
      settings: {
        itemsToShow: 5,
        snapAlign: "center",
      },
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        700: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
      brands: [
        {
          url: "https://rentpros.com/wp-content/uploads/2020/10/exp.png",
        },
        {
          url: "https://rentpros.com/wp-content/uploads/2021/02/remax-gold-logo.png",
        },
        {
          url: "https://rentpros.com/wp-content/uploads/2020/10/Keller_Williams_Realty_logo-1024x470.png",
        },
        {
          url: "https://rentpros.com/wp-content/uploads/2021/02/coldwell-banker-logo-150x150.png",
        },
        {
          url: "https://rentpros.com/wp-content/uploads/2021/02/security-pacific.png",
        },
      ],
    };
  },
  setup() {},
  updated() {
    const carouselButtons = document.getElementsByClassName(
      "carousel__pagination-button"
    );
    const carouselActiveButtons = document.getElementsByClassName(
      "carousel__pagination-button--active"
    );

    // on slide carousel
    const profile = document.getElementsByClassName("profile");
    if (profile) {
      Object.keys(profile).map((key) => {
        const btn = profile[key];
        btn.addEventListener("click", () => {
          if (carouselButtons) {
            Object.keys(carouselButtons).map((key) => {
              const btn = carouselButtons[key];
              btn.addEventListener("click", () => {
                btn.style["background"] = this.page.themeColor;
                btn.style["backgroundColor"] = this.page.themeColor;

                var carouselButtons = document.getElementsByClassName(
                  "carousel__pagination-button"
                );
                if (carouselButtons) {
                  Object.keys(carouselButtons).map((key) => {
                    const btn = carouselButtons[key];
                    const classList = Object.keys(btn.classList).map(
                      (key) => btn.classList[key]
                    );
                    if (
                      !classList.some(
                        (x) => x === "carousel__pagination-button--active"
                      )
                    ) {
                      btn.style["background"] = "#29282842";
                      btn.style["backgroundColor"] = "#29282842";
                    }
                  });
                }
              });
              const classList = Object.keys(btn.classList).map(
                (key) => btn.classList[key]
              );
              if (
                !classList.some(
                  (x) => x === "carousel__pagination-button--active"
                )
              ) {
                btn.style["background"] = "#29282842";
                btn.style["backgroundColor"] = "#29282842";
              } else {
                btn.style["background"] = this.page.themeColor;
                btn.style["backgroundColor"] = this.page.themeColor;
              }
            });
          }
        });
      });
    }

    if (carouselButtons) {
      Object.keys(carouselButtons).map((key) => {
        const btn = carouselButtons[key];
        btn.addEventListener("click", () => {
          btn.style["background"] = this.page.themeColor;
          btn.style["backgroundColor"] = this.page.themeColor;

          var carouselButtons = document.getElementsByClassName(
            "carousel__pagination-button"
          );
          if (carouselButtons) {
            Object.keys(carouselButtons).map((key) => {
              const btn = carouselButtons[key];
              const classList = Object.keys(btn.classList).map(
                (key) => btn.classList[key]
              );
              if (
                !classList.some(
                  (x) => x === "carousel__pagination-button--active"
                )
              ) {
                btn.style["background"] = "#29282842";
                btn.style["backgroundColor"] = "#29282842";
              }
            });
          }
        });
        const classList = Object.keys(btn.classList).map(
          (key) => btn.classList[key]
        );
        if (
          !classList.some((x) => x === "carousel__pagination-button--active")
        ) {
          btn.style["background"] = "#29282842";
          btn.style["backgroundColor"] = "#29282842";
        }
      });
    }

    if (carouselActiveButtons) {
      Object.keys(carouselActiveButtons).map((key) => {
        const btn = carouselActiveButtons[key];
        btn.style["background"] = this.page.themeColor;
        btn.style["backgroundColor"] = this.page.themeColor;
      });
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
<style lang="scss">
.carousel__pagination-button {
  width: 10px;
  height: 10px !important;
  border: 0;
  border-radius: 13px !important;
}

.brands {
  width: 100%;
  padding: 20px 0;
  background-color: #eaeaea;
  .title {
    margin: 10px 0 30px;
    color: #d9534f;
  }

  .items {
    width: 90%;
    margin: 0 auto;

    img {
      width: 150px;
    }
  }
}
</style>