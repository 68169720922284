<template>
  <div class="not-found">
    <div class="description">
      <div class="title">Not Found</div>
      <i class="fas fa-heart-broken any-icon"></i>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.description {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    font-size: 30pt;
}
.any-icon {
  font-size: 30pt;
  color: red;
}
</style>