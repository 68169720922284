<template>
  <div class="app-video" v-if="page?.videoURL">
    <div class="title">WATCH APP DEMO</div>
    <div style="margin-bottom: 20px" v-if="showPlayer">
      <YoutubeVue3
        style="
          border-style: solid;
          border-width: 0px 0px 0px 0px;
          border-radius: 15px 15px 15px 15px;
          box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 50%);
        "
        ref="youtube"
        :videoid="page.videoURL"
        :loop="loop"
        :autoplay="0"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { YoutubeVue3 } from "youtube-vue3";

export default {
  data() {
    return {
      loop: 1,
      height: 700,
      autoplay: 1,
      showPlayer: true,
      tempWidth: "lg",
      width: '100%',
      videoId: "0Xgsw4-ikgY",
    };
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
  methods: {
    modifyView() {
      const width = window.innerWidth;
      if (this.tempWidth === "lg" && width > 768) {
        return;
      }
      if (this.tempWidth === "sm" && width < 768) {
        return;
      }
      this.showPlayer = false;
      if (width > 768) {
        this.height = 700;
        this.width = '100%';
        this.tempWidth = "lg";
      } else {
        this.height = 300;
        this.width = '100%';
        this.tempWidth = "sm";
      }
      setTimeout(() => {
        this.showPlayer = true;
      }, 500);
    },
  },
  mounted() {
    this.modifyView();
    var ref = this;
    window.addEventListener("resize", () => {
      ref.modifyView();
    });
  },
  components: {
    YoutubeVue3,
  },
};
</script>

<style lang="scss">
.app-video {
  width: 70%;
  margin: 0 auto;
  .title {
    font-size: 25pt;
    text-align: center;
    margin: 20px;
  }
}
</style>